function generateCssVar<Type>(vars: Type, prefix: string) {
  return Object.keys(vars).reduce<{ [key in keyof Type]?: string }>(
    (theme, key) => ({ ...theme, [key]: `var(--${prefix ? prefix + '-' : ''}${key})` }),
    {},
  )
}

const block = {
  marginLR: 30, // margin-left and margin-right
  marginBottom: 30,
} as const

const mobile = {
  header: { height: 80 },
  headerPage: {
    height: 68,
  },
  headerVideo: {
    height: 85, //(height: 55, padding: 15) = 55 + 15 + 15
    separator: 4,
    headerHeight: 89, // height + separator
  },
} as const

const desktop = {
  header: {
    height: 64,
  },
  headerVideo: {
    height: 69, //(height: 55, padding: 7 50), = 55 + 7 + 7
    separator: 4,
    headerHeight: 73, // height + separator
  },
  headerHomeNav: {
    height: 35,
  },
  headerEvent: {
    height: 97,
  },
  bigHeader: {
    height: 186,
  },
  body: {
    width: 600,
  },
  sidebar: {
    width: 315,
  },
} as const

const themeColors = {
  colors: {
    transparent: 'transparent',
    deepBlue: '#000A68', // Brand Primary (Bleu profond)
    deepGrayBlue: '#0F1337',
    liveProgramBlue: '#1F234A',
    focusBlue: '#48C7FF', // Brand Secondary 1 (Bleu focus)
    rvbBlue: '#0000FF',
    yellowGolden: '#FFB628', // Brand Pour vous (yellow golden)
    lightYellow: '#FFDB9E', // Brand Presidentielle / Default
    darkestBlue: '#01052C', // Bluescale Darkest
    blurDarkestBlue: '#01052cdb', // Bluescale Darkest
    darkBlue: '#263089', // Bluescale Dark
    darkBlueOpacity: '#2630893b',
    midBlue: '#9599C2', // Bluescale Mid
    lightBlue: '#F0F0FF', // Bluescale Near
    lightGrayBlue: '#ebebfc', // Bluescale Light
    lightGrayBlueOpacity: '#ebebfc42',
    purple: '#5729FF', // Brand Secondary 3
    pink: '#ff2560',
    white: '#FFFFFF', // Bluescale/True White
    red: '#EC0000', // Brand Secondary 2 (Rouge)
    lightRed: '#ff4848', // Brand/Pour vous
    gray: '#c0c0c0',
    grayOpacity: '#ffffff42',
    lightGray: '#E0E0E2',
    lightGrayOpacity: '#e0e0e233', //#E0E0E2 opacity 0.2
    infoBlue: '#608CFE',
    backDropColor: '#0606068c',
    midGray: '#D9D9D9',
  },
  default: {
    colors: {
      bodyBackground: 'unset',
      fontColor: '#000A68',
      buttonBackground: '#F0F0FF',
      headerBackground: '#000A68',
    },
  },
  blue: {
    colors: {
      bodyBackground: '#000A68',
      fontColor: '#FFFFFF',
      buttonBackground: '#263089',
      headerBackground: '#000A68',
    },
  },
  dark: {
    colors: {
      bodyBackground: '#01052C',
      fontColor: '#FFFFFF',
      buttonBackground: '#263089',
      headerBackground: '#01052C',
    },
  },
} as const

const fonts = {
  overpass:
    "'Overpass', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen," +
    "Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;",
}

export const theme = {
  ...themeColors,
  cssVars: {
    ...generateCssVar<typeof themeColors.colors>(themeColors.colors, 'color'),
    ...generateCssVar<typeof themeColors.default.colors>(themeColors.default.colors, 'theme'),
    ...generateCssVar<typeof block>(block, 'gap'),
    ...generateCssVar<typeof fonts>(fonts, 'font'),
  },
  fonts,
  breakpoints: {
    sm: 375,
    md: 768,
    lg: 1024,
    xl: 1350,
  },
  block,
  layout: {
    maxWidth: desktop.body.width + block.marginLR * 3 + desktop.sidebar.width * 2,
    desktop,
    mobile,
  },
} as const
