import { Picture } from '@etf1-interne/tf1info_types_news/generics'
import type { ReactNode, Context } from 'react'
import { createContext, useCallback, useState } from 'react'
import { getCookie, setCookie } from '../helpers/cookies'
import { LAST_SEEN_NOTIFICATIONS } from '../constants/cookies'

export interface INotificationItem {
  id: string
  pictures: Picture
  title: string
  date: string
  hasMultiLive: boolean
  hasVideo: boolean
  link: string
  originalTitle: string
  originalText: string
}

export interface INotifications {
  wordings?: {
    title: string
    showMore: string
    placeholder?: { subtitleFirstLine: string; subtitleSecondLine?: string }
  }
  notifications?: INotificationItem[]
  fetchNotifications: () => void
  lastSeenTime: number
  updateLastSeenTime: () => void
}

export type INotificationsContext = Context<INotifications>

const initWordings = { title: '', showMore: '' }

export const NotificationsContext: INotificationsContext = createContext({
  notifications: [],
  wordings: initWordings,
  fetchNotifications: () => null,
  lastSeenTime: null,
  updateLastSeenTime: () => null,
})

export function NotificationsProvider({ children }: { children?: ReactNode }): JSX.Element {
  const [wordings, setWordings] = useState(initWordings)
  const [notifications, setNotifications] = useState([])
  const [lastSeenTime, setLastSeenTime] = useState(parseInt(getCookie(LAST_SEEN_NOTIFICATIONS)))

  const fetchNotifications = useCallback(async function fetchNotificationsMemoized() {
    try {
      const response = await fetch('/api/notifications/')
      const { data } = await response.json()
      setWordings(data?.wordings || '')
      setNotifications(data?.elementList || [])
    } catch (error) {
      console.error(error)
    }
  }, [])

  const updateLastSeenTime = () => {
    const now = new Date().getTime()
    setLastSeenTime(now)
    setCookie({ name: LAST_SEEN_NOTIFICATIONS, value: now.toString() })
  }

  return (
    <NotificationsContext.Provider
      value={{ wordings, notifications, fetchNotifications, lastSeenTime, updateLastSeenTime }}
    >
      {children}
    </NotificationsContext.Provider>
  )
}
