import type { IPageData, PageType } from '../../../types/page'
import Head from 'next/head'
import dynamic from 'next/dynamic'
import { useAmp } from 'next/amp'
import { SEOWebsite } from '../../atoms/SEOWebsite'
import config from '../../../config'
import { SEOTwitter } from '../../atoms/SEOTwitter'
import { SEOStructuredData } from '../../atoms/SEOStructuredData'
import { SEOFactChecking } from '../../atoms/SEOFactChecking'
import { SEOOpenGraph } from '../../atoms/SEOOpenGraph'
import { isPathHomeForYou } from '../../../helpers/client'
import { FORYOU_DESCRIPTION, FORYOU_ROBOTS, FORYOU_TITLE } from '../../../constants/forYou'

const SEOItemLists = dynamic(() =>
  import('../../atoms/SEOItemLists').then((mod) => mod.SEOItemLists),
)

interface ISEOLayout {
  page: IPageData
  type: PageType | 'player'
  subtype: string
}

export function SEOLayout({ page, type, subtype }: ISEOLayout): JSX.Element {
  const isAmp = useAmp()
  const {
    category,
    channel,
    createdAt,
    description: descriptionPage,
    facebookPage,
    image,
    seo,
    smo,
    app,
    title: titlePage,
    updatedAt,
    url,
    'digital-program': digitalProgram,
  } = page || {}

  const canonicalUrl = `${config.domain}${url}`
  const androidLink = app?.androidLink || ''
  const isHomeForYou = isPathHomeForYou(url || '')
  const enableOGType = isHomeForYou || subtype !== 'gigya'
  const appleItunesApp = app?.['apple-itunes-app']
  const googlePlayApp = app?.['google-play-app']

  const title: string = isHomeForYou ? FORYOU_TITLE : titlePage
  const description: string = isHomeForYou ? FORYOU_DESCRIPTION : descriptionPage
  const robots: string = isHomeForYou ? FORYOU_ROBOTS : seo?.robots

  return (
    <>
      {type === 'player' ? null : (
        <>
          <Head>
            <title>{title}</title>
            <meta name="theme-color" content="#000A68" />
            <meta name="description" content={description} />
            <meta name="keywords" content={seo?.metaKeywords} />
            {robots && <meta name="robots" content={robots} />}
            <meta name="google-site-verification" content="" />
            <link rel="canonical" href={canonicalUrl} />
            <link rel="alternate" href={androidLink} />
            <link
              rel="alternate"
              type="application/rss+xml"
              title="TF1 INFO - L'info pour tous et pour chacun"
              href="https://www.tf1info.fr/feeds/rss-une.xml"
            />
            <meta name="Content-Language" content="fr" />
            <meta name="Author-Corporate" content="TF1 Info" />
          </Head>
          <SEOTwitter title={title} description={description} image={image} smo={smo} />
          <SEOStructuredData page={page} type={type} subtype={subtype} />
          <SEOFactChecking page={page} />
          {seo?.itemLists ? <SEOItemLists modules={page?.data} /> : null}
          <SEOOpenGraph
            canonicalUrl={canonicalUrl}
            category={category}
            channel={channel}
            createdAt={createdAt}
            description={description}
            digitalProgram={digitalProgram}
            facebookPage={facebookPage}
            image={image}
            seo={seo}
            smo={smo}
            title={title}
            type={type}
            updatedAt={updatedAt}
            enableOGType={enableOGType}
          />
          <SEOWebsite />
        </>
      )}
      <Head>
        {appleItunesApp && <meta name="apple-itunes-app" content={appleItunesApp} />}
        {googlePlayApp && <meta name="google-play-app" content={googlePlayApp} />}

        <meta name="facebook-domain-verification" content={config.facebook.domainVerification} />
        <link rel="icon" type="image/png" sizes="48x48" href="/images/tf1info-logo-48x48.png" />
        <link rel="icon" type="image/png" sizes="96x96" href="/images/tf1info-logo-96x96.png" />
        <link rel="icon" type="image/png" sizes="144x144" href="/images/tf1info-logo-144x144.png" />
        <link rel="apple-touch-icon" href={`${config.domain}/images/tf1info-logo-app-76x76.png`} />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href={`${config.domain}/images/tf1info-logo-app-120x120.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href={`${config.domain}/images/tf1info-logo-app-152x152.png`}
        />

        <link
          rel="android-touch-icon"
          href={`${config.domain}/images/tf1info-logo-app-152x152.png`}
        />
        <link rel="preconnect" href="//cdn.tagcommander.com" />
        <link rel="preconnect" href={config.images} />
        <link rel="preconnect" href="//cdns.eu1.gigya.com" />
        <link rel="preconnect" href="//securepubads.g.doubleclick.net" />
        <link rel="dns-prefetch" href="//prod-player.tf1.fr" />
        <link rel="dns-prefetch" href="//mediainfo.tf1.fr" />

        <link
          href="/fonts/Overpass/qFdH35WCmI96Ajtm81GlU9vgwBcI.woff2"
          rel="preload"
          fetchPriority={isAmp ? undefined : 'high'}
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
      </Head>
    </>
  )
}
